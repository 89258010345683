/* You can add global styles to this file, and also import other style files */

body {
    font-family: 'Open Sans', Roboto, 'Microsoft JhengHei';
    background-color: #fff;
    font-size: 1em;
}

/* BOOTSTRAP NAVBAR */
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 1);
}

/* PRIMENG THEME */
.layout-wrapper .layout-content {
    margin-left: 0;
}

body .ui-tabview .ui-tabview-panels {
    padding: 1.571em 1.571em;
}

body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
    padding: 0;
}

body .ui-widget {
    font-family: 'Open Sans', Roboto, 'Microsoft JhengHei';
    font-size: 14px;
    text-decoration: none;
}

body .ui-dialog .ui-dialog-titlebar {
    font-size: 16px;
    font-weight: normal;
}

body .ui-inputgroup .ui-inputgroup-addon {
    min-width:5em;
}

/* Overwrite Bootstrap*/
.bg-sport {
  background-color: #1976d2 !important;
}

/* Custom Style*/
.header-padding {
    padding-top:30px;
}

.header-color {
    color:#1976d2;
    font-weight: 800;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
