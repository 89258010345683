//main color scheme
$primaryColor:#007ad9;
$primaryDarkColor:#116fbf;
$primaryDarkerColor:#005b9f;
$primaryTextColor:#ffffff;

//Global
$fontFamily:"Open Sans","Helvetica Neue",sans-serif;
$fontSize:14px;
$textColor:#333333;
$textSecondaryColor:lighten($textColor,25%);
$borderRadius:3px;
$transitionDuration:.3s;

//anchors
$linkColor:$primaryColor;
$linkHoverColor:$primaryDarkColor;
$linkActiveColor:$primaryDarkerColor;

//highlight
$highlightBgColor:$primaryColor;
$highlightColorText:$primaryTextColor;

//input field
$inputPadding:0.429em;
$inputBgColor:#ffffff;
$inputBorder:1px solid #a6a6a6;
$inputHoverBorderColor:#212121;
$inputFocusBorderColor:$primaryColor;
$inputFocusShadow:none;
$inputErrorBorderColor:#a80000;
$inputPlaceholderTextColor:#666666;
$inputTransition:border-color $transitionDuration;

//groups
$inputGroupBorderColor:#a6a6a6;
$inputGroupBgColor:#eaeaea;
$inputGroupTextColor:$textSecondaryColor;

//input lists
$inputListMinWidth:12em;
$inputListBgColor:#ffffff;
$inputListPadding:0;
$inputListItemPadding:0.429em 0.857em;
$inputListItemBgColor:transparent;
$inputListItemTextColor:#333333;
$inputListItemHoverBgColor:#eaeaea;
$inputListItemHoverTextColor:#333333;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightColorText;
$inputListItemBorder:0 none;
$inputListItemMargin:0;
$inputListItemDividerColor:#d8dae2;
$inputListHeaderPaddingTop:0.429em;
$inputListHeaderPaddingLeft:0.857em;
$inputListHeaderPaddingRight:0.857em;
$inputListHeaderPaddingBottom:0.429em;
$inputListHeaderMargin:0;
$inputListHeaderBgColor:#ffffff;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid #eaeaea;
$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textColor;
$inputListHeaderCloseIconHoverColor:$primaryColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding:12px;
$inputContentPanelBgColor:#ffffff;
$inputContentPanelTextColor:$textColor;

//inputs with overlays
$inputOverlayBorder:1px solid #c8c8c8;
$inputOverlayShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);

//input dropdowns
$inputDropdownIconColor:$textColor;

//button
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding:0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.143em;
$buttonBgColor:$primaryColor;
$buttonBorder:1px solid $primaryColor;
$buttonTextColor:$primaryTextColor;
$buttonHoverBgColor:$primaryDarkColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverBorderColor:$primaryDarkColor;
$buttonActiveBgColor:$primaryDarkerColor;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkerColor;
$buttonFocusBorderColor:$primaryDarkerColor;
$buttonFocusOutline:0 none;
$buttonFocusOutlineOffset:0px;
$buttonTransition:background-color $transitionDuration;
$raisedButtonShadow:0 2px 3px 0 rgba(0, 0, 0, 0.15);
$roundedButtonBorderRadius:15px;

$secondaryButtonBgColor:#f4f4f4;
$secondaryButtonBorder:1px solid #f4f4f4;
$secondaryButtonTextColor:#333333;
$secondaryButtonHoverBgColor:#c8c8c8;
$secondaryButtonHoverTextColor:#333333;
$secondaryButtonHoverBorderColor:#c8c8c8;
$secondaryButtonActiveBgColor:#a0a0a0;
$secondaryButtonActiveTextColor:#333333;
$secondaryButtonActiveBorderColor:#a0a0a0;

$infoButtonBgColor:#007ad9;
$infoButtonBorder:1px solid #007ad9;
$infoButtonTextColor:#ffffff;
$infoButtonHoverBgColor:#116fbf;
$infoButtonHoverTextColor:#ffffff;
$infoButtonHoverBorderColor:#116fbf;
$infoButtonActiveBgColor:#005b9f;
$infoButtonActiveTextColor:#ffffff;
$infoButtonActiveBorderColor:#005b9f;

$successButtonBgColor:#34A835;
$successButtonBorder:1px solid #34A835;
$successButtonTextColor:#ffffff;
$successButtonHoverBgColor:#107D11;
$successButtonHoverTextColor:#ffffff;
$successButtonHoverBorderColor:#107D11;
$successButtonActiveBgColor:#0C6B0D;
$successButtonActiveTextColor:#ffffff;
$successButtonActiveBorderColor:#0C6B0D;

$warningButtonBgColor:#ffba01;
$warningButtonBorder:1px solid #ffba01;
$warningButtonTextColor:#333333;
$warningButtonHoverBgColor:#ED990B;
$warningButtonHoverTextColor:#333333;
$warningButtonHoverBorderColor:#ED990B;
$warningButtonActiveBgColor:#D38B10;
$warningButtonActiveTextColor:#333333;
$warningButtonActiveBorderColor:#D38B10;

$dangerButtonBgColor:#e91224;
$dangerButtonBorder:1px solid #e91224;
$dangerButtonTextColor:#ffffff;
$dangerButtonHoverBgColor:#c01120;
$dangerButtonHoverTextColor:#ffffff;
$dangerButtonHoverBorderColor:#c01120;
$dangerButtonActiveBgColor:#a90000;
$dangerButtonActiveTextColor:#ffffff;
$dangerButtonActiveBorderColor:#a90000;

//checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxActiveBorderColor:$primaryColor;
$checkboxActiveBgColor:$primaryColor;
$checkboxActiveTextColor:$primaryTextColor;
$checkboxActiveHoverBgColor:$primaryDarkerColor;
$checkboxActiveHoverTextColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkerColor;
$checkboxActiveFocusBgColor:$primaryDarkerColor;
$checkboxActiveFocusTextColor:$primaryTextColor;
$checkboxActiveFocusBorderColor:$primaryDarkerColor;
$checkboxFocusBgColor:$inputBgColor;
$checkboxFocusTextColor:$primaryColor;
$checkboxFocusBorderColor:$primaryColor;
$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonActiveBorderColor:$primaryColor;
$radiobuttonActiveBgColor:$primaryColor;
$radiobuttonActiveTextColor:$primaryTextColor;
$radiobuttonActiveHoverBgColor:$primaryDarkerColor;
$radiobuttonActiveHoverTextColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkerColor;
$radiobuttonActiveFocusBgColor:$primaryDarkerColor;
$radiobuttonActiveFocusTextColor:$primaryTextColor;
$radiobuttonActiveFocusBorderColor:$primaryDarkerColor;
$radiobuttonFocusBgColor:$inputBgColor;
$radiobuttonFocusTextColor:$primaryColor;
$radiobuttonFocusBorderColor:$primaryColor;
$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration;

//togglebutton
$toggleButtonBgColor:#dadada;
$toggleButtonBorder:1px solid #dadada;
$toggleButtonTextColor:#333333;
$toggleButtonIconColor:#666666;
$toggleButtonHoverBgColor:#c8c8c8;
$toggleButtonHoverBorderColor:#c8c8c8;
$toggleButtonHoverTextColor:#333333;
$toggleButtonHoverIconColor:#212121;
$toggleButtonActiveBgColor:$primaryColor;
$toggleButtonActiveBorderColor:$primaryColor;
$toggleButtonActiveTextColor:$primaryTextColor;
$toggleButtonActiveIconColor:$primaryTextColor;
$toggleButtonActiveHoverBgColor:$primaryDarkColor;
$toggleButtonActiveHoverBorderColor:$primaryDarkColor;
$toggleButtonActiveHoverTextColor:$primaryTextColor;
$toggleButtonActiveHoverIconColor:$primaryTextColor;
$toggleButtonFocusOutline:0 none;
$toggleButtonFocusBgColor:#c8c8c8;
$toggleButtonFocusBorderColor:#c8c8c8;
$toggleButtonFocusTextColor:#333333;
$toggleButtonFocusIconColor:#212121;
$toggleButtonActiveFocusBgColor:$primaryDarkerColor;
$toggleButtonActiveFocusBorderColor:$primaryDarkerColor;
$toggleButtonActiveFocusTextColor:$primaryTextColor;
$toggleButtonActiveFocusIconColor:$primaryTextColor;

//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor:#eaeaea;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
$ratingTransition:color $transitionDuration;
$ratingCancelIconColor:#e4018d;
$ratingCancelHoverIconColor:#b5019f;
$ratingIconFontSize:20px;
$ratingStarIconColor:$textColor;
$ratingStarIconHoverColor:$primaryColor;

//slider
$sliderBgColor:#c8c8c8;
$sliderBorder:0 none;
$sliderHeight:.286em;
$sliderWidth:0.286em;
$sliderHandleWidth:1.143em;
$sliderHandleHeight:1.143em;
$sliderHandleBgColor:#ffffff;
$sliderHandleBorder:2px solid #666666;
$sliderHandleBorderRadius:100%;
$sliderHandleHoverBorder:2px solid $primaryColor;
$sliderHandleHoverBgColor:2px solid #666666; 
$sliderHandleTransition:border-color $transitionDuration;
$sliderRangeBgColor:$primaryColor;

//calendar
$calendarWidth:20em;
$calendarNavIconColor:#a6a6a6;
$calendarNavIconHoverColor:$primaryColor;
$calendarNavIconTransition:color $transitionDuration;
$calendarPadding:0.857em;
$calendarTableMargin:0.857em 0 0 0;
$calendarCellPadding:0.286em;
$calendarCellDatePadding:0.286em;
$calendarCellDateWidth:1.714em;
$calendarCellDateHeight:1.714em;
$calendarCellDateHoverBgColor:#eaeaea;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateSelectedBgColor:$primaryColor;
$calendarCellDateSelectedTextColor:$primaryTextColor;
$calendarCellDateTodayBgColor:#d0d0d0;
$calendarCellDateTodayTextColor:$textColor;
$calendarTimePickerDivider: 1px solid #d8dae2;
$calendarTimePickerPadding:0.857em;
$calendarTimePickerIconColor:$textColor;
$calendarTimePickerIconFontSize:1.286em;
$calendarTimePickerTimeFontSize:1.286em;
$calendarTimePickerIconHoverColor:$primaryColor;

//spinner
$spinnerButtonWidth:2em;

//input switch
$inputSwitchBorderRadius:8px; 
$inputSwitchOffHandleBgColor:#a6a6a6; 
$inputSwitchOffHandleHoverBgColor:#848484; 
$inputSwitchOffHandleFocusBgColor:#848484; 
$inputSwitchOnBorderColor:$primaryColor; 
$inputSwitchOnBgColor:$primaryColor; 
$inputSwitchOnHandleBgColor:$primaryDarkColor; 
$inputSwitchOnHandleFocusBgColor:$primaryDarkColor; 
$inputSwitchOnHandleHoverBgColor:$primaryDarkerColor; 
$inputSwitchTransition:border-color $transitionDuration; 
$inputSwitchHandleTransition:background-color $transitionDuration; 

//panel common
$panelHeaderBorder:1px solid #c8c8c8;
$panelHeaderBgColor:#f4f4f4;
$panelHeaderTextColor:#333333;
$panelHeaderIconColor:#848484;
$panelHeaderIconHoverColor:#333333;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:700;
$panelHeaderPadding:0.571em 1em;
$panelContentBorder:1px solid #c8c8c8;
$panelContentBgColor:#ffffff;
$panelContentTextColor:$textColor;
$panelContentPadding:0.571em 1em;
$panelFooterBorder:1px solid #c8c8c8;
$panelFooterBgColor:#ffffff;
$panelFooterTextColor:$textColor;
$panelFooterPadding:0.571em 1em;
$panelHeaderHoverBgColor:#dbdbdb;
$panelHeaderHoverBorder:1px solid #dbdbdb;
$panelHeaderHoverTextColor:#333333;
$panelHeaderHoverIconColor:#333333;
$panelHeaderActiveBgColor:$primaryColor;
$panelHeaderActiveBorder:1px solid $primaryColor;
$panelHeaderActiveTextColor:#ffffff;
$panelHeaderActiveIconColor:#ffffff;
$panelHeaderActiveHoverBgColor:$primaryDarkerColor;
$panelHeaderActiveHoverBorder:1px solid $primaryDarkerColor;
$panelHeaderActiveHoverTextColor:#ffffff;
$panelHeaderActiveHoverIconColor:#ffffff;

//accordion
$accordionSpacing:2px;

//tabview
$tabsNavBorder:1px solid #c8c8c8;
$tabsNavBgColor:transparent;
$tabHeaderSpacing: 2px;

//scrollpanel
$scrollPanelHandleBgColor:#dadada;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBgColor:#f8f8f8;

//paginator
$paginatorBgColor:#ffffff;
$paginatorBorder:1px solid #c8c8c8;
$paginatorPadding:0;
$paginatorIconColor:$textSecondaryColor;
$paginatorElementWidth:2.286em;
$paginatorElementHeight:2.286em;
$paginatorElementHoverBgColor:#eaeaea;
$paginatorElementHoverIconColor:#333333;
$paginatorElementMargin:0;
$paginatorElementPadding:0;
$paginatorElementBorder:0 none;

//table
$tableHeaderCellPadding:0.571em 0.857em;
$tableHeaderCellBgColor:#ffffff;
$tableHeaderCellTextColor:$textColor;
$tableHeaderCellFontWeight:700;
$tableHeaderCellBorder:1px solid #c8c8c8;
$tableHeaderCellHoverBgColor:#eaeaea;
$tableHeaderCellHoverTextColor:#333333;
$tableBodyRowBgColor:#ffffff;
$tableBodyRowTextColor:$textColor;
$tableBodyRowEvenBgColor:#f9f9f9;
$tableBodyRowHoverBgColor:#eaeaea;
$tableBodyRowHoverTextColor:#333333;
$tableBodyCellBorder:1px solid #c8c8c8;
$tableBodyCellPadding:0.429em 0.857em;
$tableFooterCellPadding:0.571em 0.857em;
$tableFooterCellBgColor:#ffffff;
$tableFooterCellTextColor:$textColor;
$tableFooterCellFontWeight:700;
$tableFooterCellBorder:1px solid #c8c8c8;
$tableResizerHelperBgColor:$primaryColor;

//schedule
$scheduleEventBgColor:$primaryDarkColor;
$scheduleEventBorder:1px solid $primaryDarkColor;
$scheduleEventTextColor:$primaryTextColor;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:#ffffff;

//org chart
$organizationChartConnectorColor:#c8c8c8;

//messages
$messagesMargin:1em 0;
$messagesPadding:1em;
$messagesIconFontSize:1.714em;
$messageCloseIconFontSize:1.5em;
$infoMessageBgColor:#7fbcec;
$infoMessageBorder:0 none;
$infoMessageTextColor:#212121;
$infoMessageIconColor:#212121;
$successMessageBgColor:#b7d8b7;
$successMessageBorder:0 none;
$successMessageTextColor:#212121;
$successMessageIconColor:#212121;
$warnMessageBgColor:#ffe399;
$warnMessageBorder:0 none;
$warnMessageTextColor:#212121;
$warnMessageIconColor:#212121;
$errorMessageBgColor:#f8b7bd;
$errorMessageBorder:0 none;
$errorMessageTextColor:#212121;
$errorMessageIconColor:#212121;

//message
$messagePadding:.5em;
$messageMargin:0;

//growl
$growlTopLocation:120px;
$growlIconFontSize:2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//overlays
$overlayContentBorder:1px solid #c8c8c8;
$overlayContainerShadow:0 0 6px 0 rgba(0, 0, 0, 0.16);

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1.5em;

//overlay panel
$overlayPanelCloseIconBgColor:$primaryColor;
$overlayPanelCloseIconColor:#ffffff;
$overlayPanelCloseIconHoverBgColor:$primaryDarkerColor;
$overlayPanelCloseIconHoverColor:#ffffff;

//tooltip
$tooltipBgColor:#333333;
$tooltipTextColor:#ffffff;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBgColor:#ffffff;
$stepsItemBorder:1px solid #c8c8c8;
$stepsItemNumberColor:$textColor;
$stepsItemTextColor:$textSecondaryColor;
$stepsItemWidth:28px;
$stepsItemHeight:28px;

//progressbar
$progressBarHeight:24px;
$progressBarBorder:0 none;
$progressBarBgColor:#eaeaea;
$progressBarValueBgColor:$primaryColor;

//menu
$menuBgColor:#ffffff;
$menuBorder:1px solid #c8c8c8;
$menuPadding:0;
$menuTextColor:$textColor;
$menuitemPadding:0.714em 0.857em;
$menuitemMargin:0;
$menuitemTextColor:#333333;
$menuitemIconColor:#333333;
$menuitemHoverTextColor:#333333;
$menuitemHoverIconColor:#333333;
$menuitemHoverBgColor:#eaeaea;
$menuitemActiveTextColor:#333333;
$menuitemActiveIconColor:#333333;
$menuitemActiveBgColor:#eaeaea;
$submenuHeaderMargin: 0;
$submenuPadding: 1px 0;
$overlayMenuBorder:1px solid #c8c8c8;
$overlayMenuShadow:0 0px 6px 0 rgba(0, 0, 0, 0.16);

//misc
$maskBgColor:rgba(0, 0, 0, 0.4);
$inlineSpacing:.5em;
$chipsItemMargin:0 .286em 0 0;
$dataIconColor:$textSecondaryColor;
$dataIconHoverColor:$textColor;

//general
$disabledOpacity:.5;